.BGblur {
  width: 100vw;
  height: clamp(5rem, 2.293rem + 5.639vw, 6.875rem);
  background: linear-gradient(
    0deg,
    rgba(8, 14, 18, 0) 10%,
    rgba(8, 14, 18, 1) 100%
  );
  position: fixed;
  z-index: 4500;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(10px);
    mask-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 1) 80%,
      rgba(255, 255, 255, 0) 100%
    );
    -webkit-mask-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 1) 80%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}

.NavbarItems {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  position: fixed;
  width: 70%;
  max-width: 840px;
  z-index: 5002;
  transform: translateY(-20px);
  pointer-events: none;
  margin-left: -20px;
}

.navLogo {
  width: 100%;
  max-width: 1300px;
  position: fixed;
  left: 50%;
  z-index: 5001;
  transform: translateX(-50%);
  margin: 0 auto;

  .logoDiv {
    width: clamp(5.938rem, 1.877rem + 8.459vw, 8.75rem);
    align-items: baseline;
    transform: translateX(30px) translateY(10px);
  }
}

.resumeAnimation {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: 5000;
}
