.logoDiv2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;

  margin-top: 20%;

  svg {
    width: 100%;
    height: 100%;
    fill: aliceblue;
    z-index: 5;
  }

  .neon-on {
    filter: drop-shadow(0 0 3px #fff) drop-shadow(0 0 5px #ff7e00)
      drop-shadow(0 0 15px #ff7e00) brightness(1);
  }

  .neon-off {
    filter: drop-shadow(0 0 3px #fff) drop-shadow(0 0 4px #ff7e00)
      drop-shadow(0 0 14px #ff7e00) brightness(0.9);
  }
}

@media (max-width: 768px) {
  .logoDiv2 {
    align-self: flex-end;
    margin-right: 12%;
    margin-top: 0;
  }
}
