.Contact {
  width: 100%;
  border: 3px aliceblue solid;
  border-radius: 15px;
  padding: 5rem 2rem;
  display: flex;
  margin-bottom: 2rem;
  gap: 10px;

  .rightDiv {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: aliceblue;

    h2 {
      font-family: "Passion One", sans-serif;
      font-size: 3rem;
    }

    p {
      font-family: "Yanone Kaffeesatz", sans-serif;
      font-size: 1.5rem;
    }
  }
}

@media (max-width: 768px) {
  .Contact {
    flex-direction: column-reverse;
    gap: 3rem;
    text-align: center;
    padding: 2.5rem;

    .rightDiv {
      width: 100%;
    }

    .ContactForm {
      width: 100%;
    }
  }
}
