.ProjectCard {
  display: flex;
  overflow: hidden;
  border: 3px solid aliceblue;
  border-radius: 15px;
  margin: 0 2rem;

  .canDiv {
    margin-left: 1%;
    width: 35%;
    z-index: 100;
    position: relative;

    .canDark,
    .canLight {
      position: absolute;
      transition: transform ease-in-out;
    }
  }

  .ticketDiv {
    position: relative;
    width: 30%;
    margin-top: 7%;
    margin-left: -15%;
    transition: transform ease-in-out;

    .ticketFrameLight,
    .ticketFrameDark,
    .projectPhoto {
      position: absolute;
      width: 100%;
      height: auto;
      padding-top: 0%;
      margin-left: -5%;
      transform: rotate(-5deg);
    }

    // .ticketFrameLight,
    // .canLight,
    // .ticketFrameLight {
    //   opacity: 0;
    //   transition: opacity ease-out;
    // }

    .projectPhoto {
      transition: opacity ease-out;
      z-index: -1;
    }

    .ticketFrameLight {
      z-index: 2;
    }
  }

  .infoDiv {
    padding: 1rem;
    color: aliceblue;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    gap: clamp(0.75rem, 0.269rem + 1.923vw, 2rem);
    h3 {
      font-family: "Passion One", sans-serif;
      font-weight: 700;
      margin-bottom: -20px;
      margin-top: -10px;
      font-size: clamp(3rem, 2.615rem + 1.538vw, 4rem);
    }

    .madeWith {
      font-family: "Passion One", sans-serif;
      font-weight: 100;
      font-size: clamp(1.5rem, 1.308rem + 0.769vw, 2rem);
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    .description {
      font-family: "Yanone Kaffeesatz", sans-serif;
      font-size: 1.5rem;
    }
    .buttonGroup {
      display: flex;
      gap: 1rem;

      .live,
      .github {
        align-self: flex-end;
        background-color: aliceblue;
        padding: 0.75rem;
        font-family: "Passion One", sans-serif;
        font-size: 1.5rem;
        border-radius: 10px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .ProjectCard {
    min-height: clamp(23.438rem, 4.688rem + 25vw, 25rem);
  }
}

@media (max-width: 1200px) {
  .ProjectCard {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: minmax(auto, auto) auto;
    padding-bottom: 0;
    .canDiv {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      width: 100%;
      padding-left: 10%;
    }
    .ticketDiv {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
      width: 100%;
      margin-top: 15%;
      margin-left: -10%;
    }
    .infoDiv {
      grid-column: 1 / 3;
      grid-row: 2 / 3;
      margin-top: 0;
      width: 100%;
      padding-top: 35%;
      .madeWith {
        flex-wrap: wrap;
        gap: 10px;
      }
    }
  }
}

@media (max-width: 768px) {
  .ProjectCard {
    .ticketFrameLight,
    .ticketFrameDark,
    .projectPhoto {
      padding-top: 14%;
      margin-left: -20%;
    }
    .infoDiv {
      padding-top: clamp(6.25rem, -0.561rem + 36.325vw, 16.875rem);
      h3 {
        font-size: clamp(2rem, 1.359rem + 3.419vw, 3rem);
      }
      .madeWith {
        font-size: clamp(1.25rem, 0.769rem + 2.564vw, 2rem);
        gap: 0;
        padding-top: 12px;
      }
    }
  }
}
