.ContactForm {
  display: flex;
  flex-direction: column;
  width: 40%;
  gap: 1rem;

  input,
  textarea {
    background-color: transparent;
    border: 3px aliceblue solid;
    border-radius: 5px;
    font-family: "Yanone Kaffeesatz", sans-serif;
    padding: 0.5rem;
    font-size: 1.25rem;
    color: aliceblue;
  }
  button {
    background-color: aliceblue;
    color: #080e12;
    font-family: "Passion One", sans-serif;
    font-size: clamp(1.25rem, 0.889rem + 0.752vw, 1.5rem);
    padding: 0.5rem;
    border-radius: 8px;
  }
}
