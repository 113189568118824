.SkillsCard {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 3px aliceblue solid;
  border-radius: 15px;
  overflow: hidden;
  transition: transform 1s ease-in-out;
  min-height: 260px;

  .spotDiv {
    margin-top: -55%;
    position: relative;

    .spotOn {
      position: absolute;
      left: 0;
      opacity: 0;
      transition: opacity 1s ease-in-out;

      &.visible {
        opacity: 1;
      }

      &.not-visible {
        opacity: 0;
      }
    }
  }

  .techDiv {
    width: 50%;
    margin-top: -20%;
    position: relative;
    z-index: 2001;
    svg {
      fill: aliceblue;
      opacity: 0.2; // Initially set to 20% opacity
      transition: opacity 2s ease-out; // Add transition to SVG

      &.visible {
        opacity: 1; // Transition to 100% opacity
      }

      &.not-visible {
        opacity: 0.2; // Ensure it stays at 20% opacity
      }
    }
  }

  .techName {
    background-color: aliceblue;
    color: #080e12;
    padding: 0.25rem;
    margin: 0.5rem;
    border-radius: 8px;
    width: 90%;
    text-align: center;
    opacity: 0.2; // Initially set to 20% opacity
    transition: opacity 1s ease-out;

    &.visible {
      opacity: 1; // Transition to 100% opacity
    }

    &.not-visible {
      opacity: 0.2; // Ensure it stays at 20% opacity
    }

    p {
      font-family: "Passion One", sans-serif;
      font-size: clamp(1.25rem, 0.889rem + 0.752vw, 1.5rem);
    }
  }
}

@keyframes spotFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
