.hero {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1300px;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;

  .logos {
    display: flex;
    width: 60%;
    height: 100%;
    position: absolute;
    align-items: center;
    justify-content: center;
    gap: 10px;
    z-index: 1;
  }

  img {
    width: 100%;
    max-width: 1300px;
    z-index: 2;
  }

  .brickwall {
    position: absolute;
    z-index: 0;
    width: 70%;
    height: 70%;
    opacity: 0.1;
  }
}

@media (max-width: 768px) {
  .hero {
    height: 100vh;

    .logos {
      display: flex;
      flex-direction: column;
      width: 100%;
      // height: 100%;
      position: absolute;
      align-items: center;
      justify-content: center;
      // gap: 10px;
      z-index: 1;
    }

    .brickwall {
      width: 100%;
      height: 100vh;
    }

    .curtains {
      width: 140%;
      height: 100%;
    }
  }
}
