.NavButton {
  position: relative;
  pointer-events: auto;
  transform-origin: top center;

  .rope {
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 60%;
      transform: translateY(2px);
    }
  }

  .marqueeButton {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    cursor: pointer;

    .on,
    .off {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0.4;
      transition: opacity 0.4s ease-in-out;

      &:hover,
      .marqueeButton:hover & {
        opacity: 1;
      }
    }

    .off {
      opacity: 1;

      &:hover,
      .marqueeButton:hover & {
        opacity: 0;
      }
    }

    p {
      color: aliceblue;
      position: absolute;
      margin: 0;
      pointer-events: none;
      font-family: "Passion One", sans-serif;
      font-size: clamp(1.5rem, -0.722rem + 4.63vw, 2.75rem);
      transition: text-shadow 0.2s ease-in-out;
    }

    &:hover p {
      text-shadow: 0 0 4px #fff, 0 0 20px #ff7e00;
    }
  }
}
