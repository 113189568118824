.backdropDiv {
  width: 100%;
  max-width: 1000px;
  display: flex;
  z-index: 0;
  align-items: center;
  justify-content: center;

  .backdrop {
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: clamp(-5rem, -9.511rem + 9.398vw, -1.875rem);
  }
}

@media (max-width: 768px) {
  .backdropDiv {
    .backdrop {
      // transform: scale(3);
      width: 250%;
      margin-top: 620px;
      top: auto;
    }
  }
}
