.loading-container {
  font-family: "Yanone Kaffeesatz", sans-serif;
  font-size: 2rem;
  color: aliceblue;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App {
  background-color: #080e12;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;

  .NavBar {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  h1 {
    visibility: hidden;
    position: absolute;
    height: 0px;
    width: 0px;
  }

  .ContactSection {
    padding: 0 2rem;
  }

  .resumeAnimation {
    width: 100vw;
  }
}
