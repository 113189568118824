.Skills {
  position: relative;
  z-index: 2001;

  .container {
    --scroll-padding: 0px;
    --inner-angle: calc((360 / var(--total)) * 1deg);
    --item-width: clamp(11.25rem, 7.083rem + 4.762vw, 8.125rem);
    --scroll-buff: calc(var(--item-width) * var(--scroll-ratio));
    --gap: calc(var(--item-width) * var(--gap-efficient, 0.1));
    --radius: calc(
      ((var(--item-width) + var(--gap)) / sin(var(--inner-angle))) * -1
    );
    position: relative;
    z-index: 2001;
    width: 100%;
    height: 450px;
    overflow: hidden;
    touch-action: manipulation;
    timeline-scope: --controller;
  }

  .carousel {
    transform-style: preserve-3d;
    transition: transform 0.5s ease-in-out;
  }

  .carousel-container {
    height: auto;
    width: 100%;
    mask: linear-gradient(
      calc(90deg + var(--rotate-z) * 1deg),
      transparent 0 calc(50% - var(--item-width) * var(--mask-upper)),
      white calc(50% - var(--mask-lower) * var(--item-width))
        calc(50% + var(--mask-lower) * var(--item-width)),
      transparent calc(50% + var(--mask-upper) * var(--item-width)) 100%
    );
    pointer-events: none;
    position: absolute;
    display: grid;
    place-items: center;
    inset: 0;
    transform-style: preserve-3d;
    perspective: var(--perspective);
    overflow: hidden;
  }

  .carousel li {
    height: var(--item-width);
    width: var(--item-width);
    outline-offset: 2px;
    position: absolute;
    top: 50%;
    left: 50%;
    backface-visibility: hidden;
    transform: translate(-50%, -50%)
      rotateY(calc((var(--inner-angle) * var(--index))))
      translate3d(0, 0, calc(var(--radius) * -1));
  }

  .controller {
    display: flex;
    overflow: auto;
    width: 100%;
    scroll-snap-type: x mandatory;
    scroll-timeline: --controller inline;
    align-items: center;
    padding-inline: calc(
      (50vw + var(--scroll-padding)) - (var(--scroll-buff) * 0.5)
    );
  }

  .navigation {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin: 1rem;
    position: relative;
    bottom: 230px;
    gap: 460px;
    z-index: 15000;

    button {
      background-color: aliceblue;
      color: #080e12;
      font-family: "Passion One", sans-serif;
      font-size: clamp(1.25rem, 0.889rem + 0.752vw, 1.5rem);
      padding: 0.5rem;
      border-radius: 8px;
      height: 60px;
      width: 40px;
      opacity: 70%;
      transition: opacity 0.3s ease-in-out;

      cursor: pointer;

      &:hover {
        opacity: 100%;
      }
    }
    @media (max-width: 768px) {
      bottom: 50px;
      gap: 10%;
    }
  }

  .download {
    display: flex;
    justify-content: center;
    button {
      background-color: aliceblue;
      color: #080e12;
      font-family: "Passion One", sans-serif;
      font-size: 2.5rem;
      padding: 0.5rem;
      border-radius: 8px;
      margin: 2rem;
      width: 50%;
      margin-top: -100px;

      cursor: pointer;

      @media (max-width: 768px) {
        font-size: 2rem;
        margin-top: -40px;
        width: 100%;
        height: 3rem;
      }
      &:hover {
        background-color: #f0f0f0;
      }
    }

    .resumeAnimation {
      display: none;
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      z-index: 5000;
    }
  }
}
