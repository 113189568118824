.About {
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding: 0 2rem;

  .bioDiv {
    color: aliceblue;
    font-family: "Yanone Kaffeesatz", sans-serif;
    font-size: clamp(1.3rem, 0.833rem + 0.972vw, 2rem);
    max-width: 60%;
    text-align: right;
    align-self: flex-end;
    padding-top: 15%;
    padding-bottom: 1%;
    padding-right: 10%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media (max-width: 768px) {
      font-size: clamp(1rem, 0.78rem + 1.084vw, 1.3rem);
      max-width: 100%;
      padding-right: 0;

      p:nth-of-type(2) {
        max-width: 60%;
        align-self: flex-end;
      }
    }
  }

  .brandon {
    position: absolute;
    bottom: 2%;
    width: 15%;
    left: 26%;
    max-width: 200px;
    z-index: 20;
  }

  .crates {
    z-index: 1;
  }
}
