.Footer {
  .links {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    a {
      width: 50px;
    }

    svg {
      fill: aliceblue;
    }
  }

  .copyright {
    color: aliceblue;
    font-family: "Passion One", sans-serif;
    font-size: 1.25rem;
    text-align: center;
    padding: 2rem;
  }
}
