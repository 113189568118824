.logoDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  z-index: 4500;

  svg {
    width: 100%;
    height: 100%;
    fill: aliceblue;
    z-index: 5;
    animation: neonFlickerEffect 4.5s ease-in-out infinite;
  }

  .neon-on {
    filter: drop-shadow(0 0 3px #fff) drop-shadow(0 0 5px #ff7e00)
      drop-shadow(0 0 15px #ff7e00) brightness(1);
  }

  .neon-off {
    filter: drop-shadow(0 0 3px #fff) drop-shadow(0 0 4px #ff7e00)
      drop-shadow(0 0 14px #ff7e00) brightness(0.9);
  }
}

@media (max-width: 768px) {
  .logoDiv {
    display: flex;
    width: 30%;
    align-self: flex-start;
    margin-left: 8%;
  }
}
