.MobileNav {
  position: fixed;
  z-index: 5000;
  height: 0px;
  width: 100%;
  transition: backdrop-filter 0.8s ease;

  &.is-blurred {
    backdrop-filter: blur(8px);
    background: rgba(0, 0, 0, 0.5);
  }

  .menuButton {
    display: flex;
    justify-content: flex-end;
    margin: 0 3%;
    margin-top: -70px;

    .blur {
      background: linear-gradient(
        0deg,
        rgba(8, 14, 18, 0) 0%,
        rgba(8, 14, 18, 1) 100%
      );
      backdrop-filter: blur(2px);
      width: clamp(10rem, 7.596rem + 12.821vw, 13.75rem);
      position: absolute;
      height: clamp(8.75rem, 5.545rem + 17.094vw, 13.75rem);
      margin-right: -75px;
      border-radius: 25px;
    }

    .blur2 {
      margin-right: -60px;
      margin-top: -10px;
    }

    .blur3 {
      background: none;
      backdrop-filter: blur(2px);
      margin-top: -5px;
      margin-right: -70px;
    }

    button {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      p {
        position: absolute;
        color: aliceblue;
        z-index: 2000;
        font-family: "Passion One", sans-serif;
        font-size: clamp(1.4rem, 0.226rem + 5.779vw, 3rem);
        text-shadow: 0 0 4px rgba(255, 255, 255, 0.5),
          0 0 20px rgba(255, 126, 0, 0.8);
      }
    }
  }

  .navItems {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    gap: 2vh;
    width: 100%;
    top: 0px;
    height: 100vh;

    .navButtons {
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p {
          z-index: 5500;
          font-family: "Passion One", sans-serif;
          color: aliceblue;
          font-size: clamp(2rem, 0.9rem + 5.418vw, 3.5rem);
          padding: 0 2.5rem;
          position: absolute;
          text-shadow: 0 0 4px rgba(255, 255, 255, 0.5),
            0 0 20px rgba(255, 126, 0, 0.8);
        }
      }
    }
  }
}

.backdrop-blur {
  position: fixed;
  top: 0;
  left: 0;
  width: 0%;
  height: 0%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  z-index: 4500;
  opacity: 0;
  transition: opacity 0.75s ease-in-out, backdrop-filter 0.75s ease-in-out;

  &.is-visible {
    opacity: 1;
    width: 100%;
    height: 100%;
  }
}
